import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OrderTracking = () => {
  const [orderId, setOrderId] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [error, setError] = useState('');
const navigate= useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    try {
        const consumerKey = process.env.REACT_APP_WOOCOMMERCE_CONSUMER_KEY;
        const consumerSecret = process.env.REACT_APP_WOOCOMMERCE_CONSUMER_SECRET;
        const authHeader = `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`;
      const response = await fetch(`${process.env.REACT_APP_WOOCOMMERCE_API_URL}wp-json/wc/v3/orders/${orderId}`, {
        method: 'GET',
        headers: {
          'Authorization':authHeader, // Replace with your credentials
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Order not found or email mismatch');
      }

      const order = await response.json();
      // Check if the billing email matches
      
      if (order.billing.email !== billingEmail) {
        throw new Error('Email does not match the order');
      }
      navigate(`/order-tracking/${order.id}`, { state: { orderDetails: order } })
      // Set the order details in the state
      setError(''); // Clear any previous errors
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <section className='order-tracking py-10'>
      <div className="container mx-auto md:px-8 px-4 xl:px-14 lg:px-10 2xl:px-10">
        <h1 className='text-center font-semibold text-3xl pb-10'>Track Your Order</h1>
        <form onSubmit={handleSubmit} className='grid grid-cols-1 justify-center text-center gap-8'>
          <div>
            <input
              type="text"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              className='md:w-1/2 w-2/3 rounded-md border border-gray-200 bg-gray-50 p-2.5 text-sm shadow-sm outline-none focus:z-10 focus:border-blues focus:ring-blues'
              placeholder='Enter Your Order Id'
            />
          </div>
          <div>
            <input
              type="text"
              value={billingEmail}
              onChange={(e) => setBillingEmail(e.target.value)}
              className="md:w-1/2 w-2/3 rounded-md border border-gray-200 bg-gray-50 p-2.5 text-sm shadow-sm outline-none focus:z-10 focus:border-blues focus:ring-blues"
              placeholder='Enter Your Billing Email'
            />
          </div>
          <button type="submit" className='bg-blues p-2 mx-auto text-white rounded-md'>Search Order</button>
        </form>

        {error && <p className="text-red-500 text-center">{error}</p>}
        
       
      </div>
    </section>
  );
};

export default OrderTracking;
